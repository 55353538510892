@layer base {
  :root {
    --background: #F5F4F2;
    --foreground: #262A28;

    --muted: #299E5E;
    --muted-foreground: #F5F4F2;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: #38664F;
    --secondary-foreground: #F5F4F2;

    --accent: #2FB76C;
    --accent-foreground: #F5F4F2;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 5% 64.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --muted: #299E5E;
    --muted-foreground: #F5F4F2;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: #38664F;
    --secondary-foreground: #F5F4F2;

    --accent: #2FB76C;
    --accent-foreground: #F5F4F2;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 240 3.7% 15.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

#root > div > div {
  @apply bg-background text-foreground;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.map-container {
  height: calc(50rem);
}
